@font-face { font-family: Pixel; src: url('../pocket_memesbruh03.ttf'); }

body {
  height: 100vh;
  background-image: url("../img/Background.png");
  background-color: #171B44;
  background-size: 400px 400px;
  image-rendering: pixelated;
  font-family: Pixel, sans-serif;
}

#trailer {
  position: absolute;
  display:block;
  width: 56vw;
  height: 31.5vw;
  top: 20%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

#logo {
  position: absolute;
  height: 20%;
  width: 50%;
  min-width: 580px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  background-image: url("../img/Logo.png");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  image-rendering: pixelated;
}

#hero {
  position: relative;
  z-index: -1;
  height: 70%;
  width: 100%;
  background-image: url("../img/Hero.png");
  background-size: cover;
  background-position-x: center;
  image-rendering: pixelated;
}

.button-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 100px;
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.title {
  color: #EDE6C8;
  width: 100%;
}

@media (max-width: 1000px) {
  #trailer {
    width: 80vw;
    height: 45vw;
  }

  .button-container {
    margin-top: 30px;
  }
}

@media (max-width: 650px) {
  #trailer {
    top: 45vw;
  }

  #logo {
    height: 30%;
    width: 70%;
    background-image: url("../img/Logo-narrow.png");
    background-position: top;
    min-width: 0;
  }

  .button-container {
    margin-top: 0;
  }
}
